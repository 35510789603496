import * as $ from 'jquery'
import '../assets/Prices.styl';

export module Prices {
    export function register() {
        const priceBoxHeadings = $('.price-box .price-heading');
        if (priceBoxHeadings[0]) {
            priceBoxHeadings.click(function(this: HTMLAnchorElement) {
                const priceBoxHeading = $(this);
                console.log('priceBoxHeading', priceBoxHeading, priceBoxHeading[0])
                const orderButton = priceBoxHeading.closest('.price-box').find('.btn-price-box');
                window.location = orderButton.attr('href');
            });
        }
    }
}